import cn from 'classnames';
import { useLocale, useTranslations } from 'next-intl';

import FooterLogin from '../../Footer/atoms/FooterLogin';

import DarkModeSwitch from '@components/Layout/DarkMode/DarkModeSwitch';
import Socials from '@components/Socials/Socials';
import { Link } from '@lib/navigation';
import { Promotion } from '@utils/api/server/wr/options';
import { link } from '@utils/links';
import { MenuItem, getMenuItems, categories, MobileCategory } from '@utils/menu';
import { TOS } from 'enums/legalDocuments';

const HeaderLink = ({
  t,
  item,
  horizontal,
  closeMenu,
  itemClass,
  nofollow,
}: {
  t?: (arg: string) => string;
  item: Omit<MenuItem, 'version'>;
  horizontal?: boolean;
  closeMenu: () => void;
  itemClass?: string;
  nofollow?: boolean;
}) => {
  const translate = useTranslations('menu');
  if (item.Component) {
    return <item.Component />;
  }
  if (!t) {
    t = translate;
  }
  const seo = nofollow ? 'nofollow' : '';

  return (
    <Link
      href={link(t(item.link))}
      onClick={closeMenu}
      className={
        itemClass
          ? itemClass
          : cn('block w-full', item.classNames, item.color ? `text-${item.color}` : '', {
            'mb-3 pl-2': !horizontal,
            'text-center [&:not(:first-child)]:border-l': horizontal,
          })
      }
      rel={seo}
    >
      {item.icon && <item.icon />}
      {t(item.label)}
    </Link>
  );
};

function HeaderExpendedMenu({ closeMenu, promotions, isPremiumEnabled }) {
  const t = useTranslations('menu');
  const locale = useLocale();

  const specialTOSes = (TOS[locale]?.other || []).filter(
    (tos) => tos.showFrom < Date.now() && tos.showUntil > Date.now(),
  );

  const langVisibilityFilter = (item: MenuItem) => item.visibleInLang.includes(locale);
  const categoryFilter = (category: MobileCategory) => (item: MenuItem) => item.mobileCategory === category;
  const isMobileFilter = (item: MenuItem) => item.onMobile;
  const versionFilter = (item: MenuItem) => item.version === 1;

  const menus = {} as Record<MobileCategory, MenuItem[]>;

  categories.forEach((category) => {
    menus[category] = getMenuItems(categoryFilter(category), langVisibilityFilter, isMobileFilter, versionFilter);
  });

  const menuItemsTabs = menus.regular.filter((item) => {
    return isPremiumEnabled || item.label !== 'premium';
  });

  const middleIndex = Math.ceil(menuItemsTabs.length / 2);

  const firstHalf = menuItemsTabs.splice(0, middleIndex);
  const secondHalf = menuItemsTabs.splice(-middleIndex);

  const identity = (arg: any) => arg;

  return (
    <nav className="flex min-h-[calc(100vh-104px)] flex-col overflow-auto text-[14px] text-white">
      <div className="flex grow flex-col">
        <div className="flex w-full grow justify-around px-8 py-4 text-center font-bold">
          <div className="flex flex-col justify-center">
            {firstHalf.map((item) => (
              <HeaderLink closeMenu={closeMenu} key={`footer-menu-${item.link}`} item={item} />
            ))}
          </div>
          <div className="my-2 block min-h-full w-0.5 border-l border-premium-900" />
          <div className="flex flex-col justify-center">
            {secondHalf.map((item) => (
              <HeaderLink closeMenu={closeMenu} key={`footer-menu-${item.link}`} item={item} />
            ))}
          </div>
        </div>
        {menus.mid.length > 0 && (
          <div className="flex grow flex-col justify-center px-8 py-4 text-center font-bold">
            {promotions.map((promo: Promotion) => (
              <HeaderLink
                t={identity}
                closeMenu={closeMenu}
                key={`footer-menu-${promo.link}`}
                nofollow={true}
                item={{
                  label: promo.label,
                  visibleInLang: [],
                  desktopCategory: 'section',
                  mobileCategory: 'mid',
                  onDesktop: true,
                  onMobile: true,
                  link: promo.link,
                }}
              />
            ))}
            {specialTOSes.map((tos) => (
              <HeaderLink
                t={identity}
                closeMenu={closeMenu}
                key={`footer-menu-${tos.slug}`}
                nofollow={true}
                item={{
                  label: tos.title,
                  visibleInLang: ['pl'],
                  mobileCategory: 'mid',
                  desktopCategory: 'section',
                  onDesktop: true,
                  onMobile: true,
                  link: `/rules/${tos.slug}`,
                }}
              />
            ))}
            {menus.mid.map((item) => (
              <HeaderLink closeMenu={closeMenu} key={`footer-menu-${item.link}`} item={item} />
            ))}
          </div>
        )}
        {menus.advertise.length > 0 && (
          <div className="flex grow flex-col justify-center bg-premium-medium py-2 text-center dark:bg-premium-dark">
            {menus.advertise.length > 1 && <h6 className="mb-1 py-1 text-center text-[14px]">{t('advertise')}:</h6>}
            <div className="flex flex-row items-center px-8 font-bold">
              {menus.advertise.map((item) => (
                <HeaderLink
                  closeMenu={closeMenu}
                  key={`footer-menu-${item.link}`}
                  nofollow={true}
                  item={item}
                  horizontal
                />
              ))}
            </div>
          </div>
        )}
        <div className="flex w-full grow flex-wrap items-center justify-evenly gap-x-2 px-2 py-4">
          <div className="flex flex-row gap-x-2">
            <Socials names={['yt', 'fb', 'tt', 'li']} size="small" />
          </div>
          <FooterLogin labels={{ logIn: t('logIn'), logOut: t('logout') }} menuClose={closeMenu} />
          <DarkModeSwitch mobile />
        </div>
        {menus.footer.length > 0 && (
          <div className="flex grow flex-col justify-center px-8 pb-8 pt-2 text-center text-[14px]">
            {menus.footer.map((item) => (
              <HeaderLink
                itemClass="block pb-1"
                closeMenu={closeMenu}
                key={`footer-menu-${item.link}`}
                nofollow={true}
                item={item}
              />
            ))}
          </div>
        )}
      </div>
    </nav>
  );
}

export default HeaderExpendedMenu;
